import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import HashLoader from "react-spinners/HashLoader"

const cookies = new Cookies()

/* Login Pages */
const NewLogin = lazy(() => import('../Pages/NewLogin'))

/* Components Pages */
// const DashLapangan = lazy(() => import('../Pages/DashLapangan'))
const TableRegisterPersil = lazy(() => import('../Pages/TableRegisterPersil'))
const DashKlarifikasi = lazy(() => import('../Pages/DashKlarifikasi'))
const DashArsip = lazy(() => import('../Pages/DashArsip'))
const TableSurveyKoridor = lazy(() => import('../Pages/TableSurveyKoridor'))
const TableSurveyDone = lazy(() => import('../Pages/TableSurveyDone'))
const TableSurveyDoneTwo = lazy(() => import('../Pages/TableSurveyDoneTwo'))
const TableSurveyDoneThree = lazy(() => import('../Pages/TableSurveyDoneThree'))
const TableSurveyDoneBantib = lazy(() => import('../Pages/TableSurveyDoneBantib'))
const TableUser = lazy(() => import('../Pages/TableUser'))
const Logout = lazy(() => import('../Pages/Logout'))
const Navbar = lazy(() => import('../Pages/Navbar'))
// const SidebarLapangan = lazy(() => import('../Pages/SidebarLapangan'))
// const SidebarVerifikasi = lazy(() => import('../Pages/SidebarVerifikasi'))
// const HomeArsip = lazy(() => import('../Pages/HomeArsip'))
// const Sidebar = lazy(() => import('../Pages/Sidebar'))
// const SidebarArsip = lazy(() => import('../Pages/SidebarArsip'))
const AddSimak = lazy(() => import('../Pages/AddSimak'))
const AddSurveyKoridor = lazy(() => import('../Pages/AddSurveyKoridor'))
// const VerifySimak = lazy(() => import('../Pages/VerifySimak'))
const VerifySLF = lazy(() => import('../Pages/VerifySLF'))
const VerifyIMB = lazy(() => import('../Pages/VerifyIMB'))
const VerifySurveyKoridor = lazy(() => import('../Pages/VerifySurveyKoridor'))
const ClarifySimak = lazy(() => import('../Pages/ClarifySimak'))
const ArchiveSimak = lazy(() => import('../Pages/AddArchive'))
const UploadExcel = lazy(() => import('../Pages/UploadExcel'))
const AddPengguna = lazy(() => import('../Pages/AddPengguna'))
const DashAdmin = lazy(() => import('../Pages/DashAdmin'))
const SLFMain = lazy(() => import('../Pages/SLFMain'))
const ReuploadSurveyKoridor = lazy(() => import('../Pages/ReuploadSurveyKoridor'))
const ReuploadRegisterPersil = lazy(() => import('../Pages/ReuploadRegisterPersil'))
const TableKlarifikasiPanggilan = lazy(() => import('../Pages/TableKlarifikasiPanggilan'))
const TableKlarifikasiOne = lazy(() => import('../Pages/TableKlarifikasiOne'))
const TableKlarifikasiTwo = lazy(() => import('../Pages/TableKlarifikasiTwo'))
const TableKlarifikasiThree = lazy(() => import('../Pages/TableKlarifikasiThree'))
const KlarifikasiKoridorPanggilan = lazy(() => import('../Pages/KlarifikasiKoridorPanggilan'))
const KlarifikasiKoridorOne = lazy(() => import('../Pages/KlarifikasiKoridorOne'))
const KlarifikasiKoridorTwo = lazy(() => import('../Pages/KlarifikasiKoridorTwo'))
const KlarifikasiKoridorThree = lazy(() => import('../Pages/KlarifikasiKoridorThree'))
const TableStopKlarifikasiKoridor = lazy(() => import('../Pages/TableStopKlarifikasiKoridor'))
const StopKlarifikasiKoridor = lazy(() => import('../Pages/StopKlarifikasiKoridor'))
const TableRekapKoridor = lazy(() => import('../Pages/TableRekapKoridor'))
const ModifySurveyKoridor = lazy(() => import('../Pages/ModifySurveyKoridor'))
const UploadExcelKoridor = lazy(() => import('../Pages/UploadExcelKoridor'))
const ReportAdmin = lazy(() => import('../Pages/ReportAdmin'))
const TableVerifikasiKoridor = lazy(() => import('../Pages/TableVerifikasiKoridor'))
const TableRekapSegel = lazy(() => import('../Pages/TableRekapPenyegelan'))
const TableRedundan = lazy(() => import('../Pages/TableRedundantSurvey'))
const TableKlarifikasiBantib = lazy(() => import('../Pages/TableKlarifikasiBantib'))
const KlarifikasiKoridorBantib = lazy(() => import('../Pages/KlarifikasiKoridorBantib'))
const TableRekapTidakDitindaklanjuti = lazy(() => import('../Pages/TableRekapTidakDitindaklanjuti'))
const ModalFiles = lazy(() => import('../Pages/ModalFiles'))
const ViewDetilPersil = lazy(() => import('../Pages/ViewDetilPersil'))
const TableDetilRekapNewPengendalian = lazy(() => import('../Pages/TableDetilRekapNewPengendalian'))
const TableSurveyPencabutanPenyegelan = lazy(() => import('../Pages/TableSurveyPencabutanPenyegelan'))
const TableReverifikasiKoridor = lazy(() => import('../Pages/TableReverifikasiKoridor'))
const TableResurveyDone = lazy(() => import('../Pages/TableResurveyDone'))
const TableResetVerifikasi = lazy(() => import('../Pages/TableResetVerifikasi'))
const TableTandaTerima = lazy(() => import('../Pages/TableTandaTerima'))
const ReuploadTandaTerima = lazy(() => import('../Pages/ReuploadTandaTerima'))
const TableHiddenSurvey = lazy(() => import('../Pages/TableHiddenSurvey'))

/* style for fallback suspense spinners */
 const override = {
  position: "fixed", 
  top: "50%", 
  left: "50%", 
  transform: "translate(-50%, -50%)" 
}

class MainLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLogin: false,
    }
  }

  render() {
    if( !cookies.get('udatxu') ) {
      return(
        <BrowserRouter>        
          <Suspense fallback={ <HashLoader color="purple" loading="true" cssOverride={ override } size={ 150 } aria-label="Loading Spinner" data-testid="loader" speedMultiplier={ 1 } /> }>
            <Routes>
              <Route path="/login" element= { <NewLogin /> } />
              <Route path="*" element={ <Navigate to='/login' replace={true}/> } />
            </Routes> 
          </Suspense>
        </BrowserRouter>
      ) 
    } else {
      if( cookies.get('udatxu').role === 'Administrator' && cookies.get('udatxu').status === 'Active' ) {
        return(
          <BrowserRouter>
            <Suspense fallback={ <HashLoader color="purple" loading="true" cssOverride={ override } size={ 150 } aria-label="Loading Spinner" data-testid="loader" speedMultiplier={ 1 } /> }>
              <div className="container-fluid" id="admin-page">
                <div className="row g-1">
                  <div className="wrapper-navbar">
                    <Navbar />
                  </div>
                  <div className="wrapper-content">
                    <Routes>
                      {/* First Page */}
                      <Route path="/dashadmin" element={ <DashAdmin /> } />
                      {/* Survey Koridor */}
                      <Route path="/tablekoridor" element={ <TableSurveyKoridor /> } />
                      <Route path="/addsurveykoridor" element={ <AddSurveyKoridor /> } />
                      <Route path="/uploadsurveykoridor" element={ <UploadExcelKoridor /> } />
                      <Route path="/modifysurveykoridor/:params" element={ <ModifySurveyKoridor /> } />
                      <Route path="/tablesurveypencabutan" element={ <TableSurveyPencabutanPenyegelan /> } />
                      <Route path="/tableverifikasikoridor" element={ <TableVerifikasiKoridor /> } />
                      <Route path="/tablereverifikasikoridor" element={ <TableReverifikasiKoridor /> } />
                      <Route path="/verifysurveykoridor/:params" element={ <VerifySurveyKoridor /> } />
                      <Route path="/tablereupkoridor" element={ <TableSurveyDone /> } />
                      <Route path="/tablereupkoridortwo" element={ <TableSurveyDoneTwo /> } />
                      <Route path="/tablereupkoridorthree" element={ <TableSurveyDoneThree /> } />
                      <Route path="/tablereupkoridorbantib" element={ <TableSurveyDoneBantib /> } />
                      <Route path="/tablereupkoridorpencabutan" element={ <TableResurveyDone /> } />
                      <Route path="/reuploadsurveykoridor/:params"  element={ <ReuploadSurveyKoridor /> } />
                      <Route path="/klarifikasipanggilan" element={ <TableKlarifikasiPanggilan /> } />
                      <Route path="/klarifikasipanggilan/:params" element={ <KlarifikasiKoridorPanggilan /> } />
                      <Route path="/klarifikasikoridorsatu" element={ <TableKlarifikasiOne /> } />
                      <Route path="/klarifikasikoridorsatu/:params" element={ <KlarifikasiKoridorOne /> } />                        
                      <Route path="/klarifikasikoridordua" element={ <TableKlarifikasiTwo /> } />
                      <Route path="/klarifikasikoridordua/:params" element={ <KlarifikasiKoridorTwo /> } />
                      <Route path="/klarifikasikoridortiga" element={ <TableKlarifikasiThree /> } />
                      <Route path="/klarifikasikoridortiga/:params" element={ <KlarifikasiKoridorThree /> } />                        
                      <Route path="/klarifikasikoridorbantib" element={ <TableKlarifikasiBantib /> } />
                      <Route path="/klarifikasikoridorbantib/:params" element={ <KlarifikasiKoridorBantib /> } />
                      <Route path="/stopklarifikasikoridor" element={ <TableStopKlarifikasiKoridor /> } />
                      <Route path="/stopklarifikasikoridor/:params" element={ <StopKlarifikasiKoridor /> } />
                      <Route path="/tablerekapkoridor" element={ <TableRekapKoridor /> } />
                      <Route path="/viewdetilpersil/:params" element={ <ViewDetilPersil /> } />
                      <Route path="/tablerekapsegel" element={ <TableRekapSegel /> } />
                      <Route path="/tablerekapnontl" element={ <TableRekapTidakDitindaklanjuti /> } />
                      <Route path="/reportadmin" element={ <ReportAdmin /> } />
                      <Route path="/listfiles/:params" element={ <ModalFiles /> } />
                      <Route path="/tabledetailnewpengendalian/jmlPersil" element={ <TableDetilRekapNewPengendalian pages={ "jmlPersil" } /> } />
                      <Route path="/tabledetailnewpengendalian/ttlIMB" element={ <TableDetilRekapNewPengendalian pages={ "ttlIMB" } /> } />
                      <Route path="/tabledetailnewpengendalian/ttlNoIMB" element={ <TableDetilRekapNewPengendalian pages={ "ttlNoIMB" } /> } />
                      <Route path="/tabledetailnewpengendalian/verifikasi" element={ <TableDetilRekapNewPengendalian pages={ "verifikasi" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlNoIMBPeringatan" element={ <TableDetilRekapNewPengendalian pages={ "tlNoIMBPeringatan" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlNoIMBPenyegelan" element={ <TableDetilRekapNewPengendalian pages={ "tlNoIMBPenyegelan" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlIMBSP1" element={ <TableDetilRekapNewPengendalian pages={ "tlIMBSP1" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlIMBSP2" element={ <TableDetilRekapNewPengendalian pages={ "tlIMBSP2" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlIMBSP3" element={ <TableDetilRekapNewPengendalian pages={ "tlIMBSP3" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlIMBPenyegelan" element={ <TableDetilRekapNewPengendalian pages={ "tlIMBPenyegelan" } /> } />
                      <Route path="/tabledetailnewpengendalian/hasilTLNoIMB" element={ <TableDetilRekapNewPengendalian pages={ "hasilTLNoIMB" } /> } />
                      <Route path="/tabledetailnewpengendalian/hasilTLIMB" element={ <TableDetilRekapNewPengendalian pages={ "hasilTLIMB" } /> } />
                      <Route path="/tabletandaterima" element={ <TableTandaTerima /> } />
                      <Route path="/reuploadtandaterima/:params" element={ <ReuploadTandaTerima /> } />
                      {/* Data Management */}
                      <Route path="/tableredundan" element={ <TableRedundan /> } />
                      <Route path="/tablehidden" element={ <TableHiddenSurvey /> } />
                      <Route path="/tableresetverifikasi" element={ <TableResetVerifikasi /> } />                      
                      {/* Manage Account */}
                      <Route path="/tableuser" element={ <TableUser /> } />
                      <Route path="/addpengguna" element={ <AddPengguna /> } />
                      {/* SLF IMB Awal */}
                      <Route path="/tableregisterpersil" element={ <TableRegisterPersil /> } />
                      <Route path="/addsimak" element={ <AddSimak /> } />
                      <Route path="/verifyslf/:params" element={ <VerifySLF /> } />
                      <Route path="/verifyimb/:params" element={ <VerifyIMB /> } />
                      <Route path="/reuploadregisterpersil/:params"  element={ <ReuploadRegisterPersil /> } />
                      <Route path="/uploadexcel"  element={ <UploadExcel /> } />
                      <Route path="/slfmain" element={ <SLFMain /> } />
                      <Route path="/klarifikasihomepage" element={ <DashKlarifikasi /> } /> 
                      <Route path="/arsiphomepage" element={ <DashArsip /> } />
                      <Route path="/clarifysimak/:params" element={ <ClarifySimak /> } />
                      <Route path="/archivesimak/:params" element={ <ArchiveSimak /> } />
                      {/* Logout Page and Redirect */}
                      <Route path="/logout" element={ <Logout /> } />
                      <Route path="*" element={ <Navigate to='/dashadmin' /> } replace={true} />
                    </Routes>
                  </div>
                </div>
              </div> 
            </Suspense>
          </BrowserRouter>
        )        
      } else if( cookies.get('udatxu').role === 'Verifikasi' && cookies.get('udatxu').status === 'Active' ) {
        return(
          <BrowserRouter>
            <Suspense fallback={ <HashLoader color="purple" loading="true" cssOverride={ override } size={ 150 } aria-label="Loading Spinner" data-testid="loader" speedMultiplier={ 1 } /> }>
              <div className="container-fluid" id="admin-page">
                <div className="row g-1">
                  <div className="wrapper-navbar">
                    <Navbar />
                  </div>
                  <div className="wrapper-content">
                    <Routes>
                      {/* First Page */}
                      <Route path="/dashadmin" element={ <DashAdmin /> } />
                      {/* Survey Koridor */}
                      <Route path="/tableverifikasikoridor" element={ <TableVerifikasiKoridor /> } />
                      <Route path="/tablereverifikasikoridor" element={ <TableReverifikasiKoridor /> } />
                      <Route path="/verifysurveykoridor/:params" element={ <VerifySurveyKoridor /> } />
                      <Route path="/tablereupkoridor" element={ <TableSurveyDone /> } />
                      <Route path="/tablereupkoridortwo" element={ <TableSurveyDoneTwo /> } />
                      <Route path="/tablereupkoridorthree" element={ <TableSurveyDoneThree /> } />
                      <Route path="/tablereupkoridorbantib" element={ <TableSurveyDoneBantib /> } />
                      <Route path="/tablereupkoridorpencabutan" element={ <TableResurveyDone /> } />
                      <Route path="/reuploadsurveykoridor/:params"  element={ <ReuploadSurveyKoridor /> } />
                      <Route path="/klarifikasipanggilan" element={ <TableKlarifikasiPanggilan /> } />
                      <Route path="/klarifikasipanggilan/:params" element={ <KlarifikasiKoridorPanggilan /> } />
                      <Route path="/klarifikasikoridorsatu" element={ <TableKlarifikasiOne /> } />
                      <Route path="/klarifikasikoridorsatu/:params" element={ <KlarifikasiKoridorOne /> } />                        
                      <Route path="/klarifikasikoridordua" element={ <TableKlarifikasiTwo /> } />
                      <Route path="/klarifikasikoridordua/:params" element={ <KlarifikasiKoridorTwo /> } />
                      <Route path="/klarifikasikoridortiga" element={ <TableKlarifikasiThree /> } />
                      <Route path="/klarifikasikoridortiga/:params" element={ <KlarifikasiKoridorThree /> } />
                      <Route path="/stopklarifikasikoridor" element={ <TableStopKlarifikasiKoridor /> } />
                      <Route path="/stopklarifikasikoridor/:params" element={ <StopKlarifikasiKoridor /> } />
                      <Route path="/tablerekapkoridor" element={ <TableRekapKoridor /> } />
                      <Route path="/viewdetilpersil/:params" element={ <ViewDetilPersil /> } />
                      <Route path="/tablerekapsegel" element={ <TableRekapSegel /> } />
                      <Route path="/tablerekapnontl" element={ <TableRekapTidakDitindaklanjuti /> } />
                      <Route path="/tableredundan" element={ <TableRedundan /> } />
                      <Route path="/tabledetailnewpengendalian/jmlPersil" element={ <TableDetilRekapNewPengendalian pages={ "jmlPersil" } /> } />
                      <Route path="/tabledetailnewpengendalian/ttlIMB" element={ <TableDetilRekapNewPengendalian pages={ "ttlIMB" } /> } />
                      <Route path="/tabledetailnewpengendalian/ttlNoIMB" element={ <TableDetilRekapNewPengendalian pages={ "ttlNoIMB" } /> } />
                      <Route path="/tabledetailnewpengendalian/verifikasi" element={ <TableDetilRekapNewPengendalian pages={ "verifikasi" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlNoIMBPeringatan" element={ <TableDetilRekapNewPengendalian pages={ "tlNoIMBPeringatan" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlNoIMBPenyegelan" element={ <TableDetilRekapNewPengendalian pages={ "tlNoIMBPenyegelan" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlIMBSP1" element={ <TableDetilRekapNewPengendalian pages={ "tlIMBSP1" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlIMBSP2" element={ <TableDetilRekapNewPengendalian pages={ "tlIMBSP2" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlIMBSP3" element={ <TableDetilRekapNewPengendalian pages={ "tlIMBSP3" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlIMBPenyegelan" element={ <TableDetilRekapNewPengendalian pages={ "tlIMBPenyegelan" } /> } />
                      <Route path="/tabledetailnewpengendalian/hasilTLNoIMB" element={ <TableDetilRekapNewPengendalian pages={ "hasilTLNoIMB" } /> } />
                      <Route path="/tabledetailnewpengendalian/hasilTLIMB" element={ <TableDetilRekapNewPengendalian pages={ "hasilTLIMB" } /> } />
                      <Route path="/tabletandaterima" element={ <TableTandaTerima /> } />
                      <Route path="/reuploadtandaterima/:params" element={ <ReuploadTandaTerima /> } />
                      {/* Logout Page and Redirect */}
                      <Route path="/logout" element={ <Logout /> } />
                      <Route path="*" element={ <Navigate to='/tablekoridor' /> } replace={true} />
                    </Routes>
                  </div>
                </div>
              </div> 
            </Suspense>
          </BrowserRouter>
        )        
      } else if( cookies.get('udatxu').role === 'Lapangan' && cookies.get('udatxu').status === 'Active' ) {
        return(
          <BrowserRouter>
            <Suspense fallback={ <HashLoader color="purple" loading="true" cssOverride={ override } size={ 150 } aria-label="Loading Spinner" data-testid="loader" speedMultiplier={ 1 } /> }>
              <div className="container-fluid" id="field-officer-page">
                <div className="row g-1">
                  <div className="wrapper-navbar">
                    <Navbar />
                  </div>
                  <div className="wrapper-content">
                    <Routes>
                      {/* First Page */}
                      <Route path="/dashadmin" element={ <DashAdmin /> } />
                      {/* Survey Koridor */}
                      <Route path="/tablekoridor" element={ <TableSurveyKoridor /> } />
                      <Route path="/addsurveykoridor" element={ <AddSurveyKoridor /> } />
                      <Route path="/uploadsurveykoridor" element={ <UploadExcelKoridor /> } />                        
                      <Route path="/modifysurveykoridor/:params" element={ <ModifySurveyKoridor /> } />
                      <Route path="/tablesurveypencabutan" element={ <TableSurveyPencabutanPenyegelan /> } />
                      <Route path="/viewdetilpersil/:params" element={ <ViewDetilPersil /> } />
                      <Route path="/tabledetailnewpengendalian/jmlPersil" element={ <TableDetilRekapNewPengendalian pages={ "jmlPersil" } /> } />
                      <Route path="/tabledetailnewpengendalian/ttlIMB" element={ <TableDetilRekapNewPengendalian pages={ "ttlIMB" } /> } />
                      <Route path="/tabledetailnewpengendalian/ttlNoIMB" element={ <TableDetilRekapNewPengendalian pages={ "ttlNoIMB" } /> } />
                      <Route path="/tabledetailnewpengendalian/verifikasi" element={ <TableDetilRekapNewPengendalian pages={ "verifikasi" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlNoIMBPeringatan" element={ <TableDetilRekapNewPengendalian pages={ "tlNoIMBPeringatan" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlNoIMBPenyegelan" element={ <TableDetilRekapNewPengendalian pages={ "tlNoIMBPenyegelan" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlIMBSP1" element={ <TableDetilRekapNewPengendalian pages={ "tlIMBSP1" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlIMBSP2" element={ <TableDetilRekapNewPengendalian pages={ "tlIMBSP2" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlIMBSP3" element={ <TableDetilRekapNewPengendalian pages={ "tlIMBSP3" } /> } />
                      <Route path="/tabledetailnewpengendalian/tlIMBPenyegelan" element={ <TableDetilRekapNewPengendalian pages={ "tlIMBPenyegelan" } /> } />
                      <Route path="/tabledetailnewpengendalian/hasilTLNoIMB" element={ <TableDetilRekapNewPengendalian pages={ "hasilTLNoIMB" } /> } />
                      <Route path="/tabledetailnewpengendalian/hasilTLIMB" element={ <TableDetilRekapNewPengendalian pages={ "hasilTLIMB" } /> } />                      
                      <Route path="/tabletandaterima" element={ <TableTandaTerima /> } />
                      <Route path="/reuploadtandaterima/:params" element={ <ReuploadTandaTerima /> } />                      
                      {/* Logout Page and Redirect */}
                      <Route path="/logout" element={ <Logout /> } />
                      <Route path="*" element={ <Navigate to='/tablekoridor' /> } replace={true} />
                    </Routes>
                  </div>
                </div>
              </div> 
            </Suspense>
          </BrowserRouter>
        )        
      }
    }
  }
}

export default MainLogin