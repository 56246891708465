// import { React, StrictMode } from 'react'
import { React } from 'react'
import { createRoot } from 'react-dom/client'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import App from './Components/Layout/MainPages'

library.add(fas, far);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// root.render(
//   <StrictMode>
//     <App />
//   </StrictMode>
// );

root.render(<App />);